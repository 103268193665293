import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BringLogo from './BringLogo';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="pagelayout__footer">
      <div className="footer__wrap">
        <div className="customerservice">
          <a className="customerservice__logo" href="https://bring.no/" rel="noopener noreferrer" title="bring.no">
            <BringLogo />
          </a>
          <div className="customerservice__list">
            <h2 className="text-basic">{t('welcome.customerservice.label')}</h2>
            <ul className="customerservice__grid">
              <li className="mbxs mrm">
                <a className="linkish" rel="noopener noreferrer" href="https://www.bring.no/kundeservice">
                  {t('welcome.customerservice.no')}
                </a>
              </li>
              <li className="mbxs mrm">
                <a className="linkish" rel="noopener noreferrer" href="https://www.bring.se/kundservice-foretag">
                  {t('welcome.customerservice.se')}
                </a>
              </li>
              <li className="mbxs mrm">
                <a className="linkish" rel="noopener noreferrer" href="https://www.bring.dk/kunder/kundeservice">
                  {t('welcome.customerservice.dk')}
                </a>
              </li>
              <li className="mbxs">
                <a
                  className="linkish"
                  rel="noopener noreferrer"
                  href="https://www.bring.fi/asiakaspalvelu/yhteystiedot"
                >
                  {t('welcome.customerservice.fi')}
                </a>
              </li>
            </ul>
          </div>
          <div className="customerservice__terms">
            <h2 className="text-basic">{t('welcome.terms')}</h2>
            {t('welcome.download')}
            &nbsp;
            <a
              className="linkish"
              target="_blank"
              rel="noopener noreferrer"
              href="https://developer.bring.com/General_Terms_and_Conditions_for_Self_Service_Solutions.pdf"
            >
              General Terms and Conditions for Self Service Solutions
            </a>
          </div>
        </div>
        <div className="terms">
          <span className="mrm">&copy; {new Date().getFullYear()} Posten Bring AS</span>
          <a className="mrm" rel="noopener noreferrer" href="https://www.bring.no/privacy-policy">
            {t('welcome.policy')}
          </a>
          <a rel="noopener noreferrer" href="https://www.bring.no/bruk-av-cookies">
            {t('welcome.cookies')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
