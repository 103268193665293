import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en';
import nb from './nb';
import sv from './sv';
import da from './da';
i18next.use(initReactI18next).init({
  lng: 'nb',
  fallbackLng: 'nb',
  resources: {
    en: { translation: en },
    no: { translation: nb },
    nb: { translation: nb },
    da: { translation: da },
    sv: { translation: sv },
  },
  react: {
    useSuspense: true,
  },
});
export default i18next;
