import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import i18next from '../../common/i18n/i18n';
import { getLocale } from '../../common/utils/utilities';
import ErrorBoundary from '../../common/components/ErrorBoundary';
import ApplicationError from '../../common/components/ApplicationError';
import NotFound from '../../common/components/NotFound';
import Welcome from './Welcome';
import './App.css';

const App = () => {
  useEffect(() => {
    i18next.changeLanguage(getLocale());
  }, []);

  return (
    <ErrorBoundary fallbackUI={<ApplicationError />}>
      <div className="main-container">
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </ErrorBoundary>
  );
};

export default App;
