import * as locale from './utilities';
import globals from './globals';
import i18next from '../i18n/i18n';
import { UserInfo } from '../types/common';

const getLocale = () => {
  if (i18next.language && i18next.language !== '') {
    return i18next.language;
  }
  return locale.getQueryParam('lang') || locale.getQueryParam('locale') || navigator.language || globals.defaultLocale;
};

const getQueryParam = (param: string) => {
  const url = locale.getWindowLocation().href;
  const regex = new RegExp('[?&]' + param + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const getWindowLocation = () => window.location;

const initI18n = (userLocale: string) => {
  i18next.changeLanguage(userLocale);
};

const initUserContext = (userInfo: UserInfo) => {
  if (userInfo) {
    const userLocale = userInfo.language;
    globals.userType = userInfo.role;
    globals.fullName = userInfo.userFullName;
    globals.email = userInfo.userEmail;
    initI18n(userLocale ? userLocale : 'nb');
  }
};

const getEnvironmentForUrl = () => {
  return locale.getWindowLocation().host.includes('qa.')
    ? 'https://www.qa.mybring.com'
    : locale.getWindowLocation().host.includes('test.') || locale.getWindowLocation().host.includes('localhost')
    ? 'https://www.test.mybring.com'
    : locale.getWindowLocation().host.includes('local.')
    ? 'http://local.mybring.com'
    : 'https://www.mybring.com';
};

const getFrontpageEnvironmentForUrl = () => {
  return getEnvironmentForUrl();
};

const getReportsDashboardUrl = (reportsDashboardContextPath: string) => {
  return getEnvironmentForUrl() + reportsDashboardContextPath;
};
export const addScript = (src: any, id: any) => {
  const element = document.getElementById(id);
  element && element.parentNode && element.parentNode.removeChild(element);
  const scriptTag = document.createElement('script');
  scriptTag.setAttribute('src', src);
  scriptTag.setAttribute('id', id);
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
};

export const addTextScript = (scriptText: string, id: any) => {
  const element = document.getElementById(id);
  element && element.parentNode && element.parentNode.removeChild(element);
  const scriptTag = document.createElement('script');
  const script = document.createTextNode(scriptText);
  scriptTag.appendChild(script);
  scriptTag.setAttribute('id', id);
  scriptTag.async = true;
  document.body.appendChild(scriptTag);
};

const numberWithSpaces = (value: number) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

const formatDate = (date: string, withTime: boolean = false) => {
  let newDate = new Date(date);
  let dateString = newDate.toLocaleDateString('nb-NO', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  if (withTime) {
    let timeString = newDate.toLocaleTimeString('nb-No', {
      hour: '2-digit',
      minute: '2-digit',
    });

    return `${dateString} - ${timeString}`;
  }

  return dateString;
};

const dateAndTimeWithoutTimeZone = (dateAndTime: string) => {
  let date = new Date(dateAndTime);
  return date
    .toISOString()
    .replace('T', ' ')
    .replace(/\.\d{3}Z$/, '');
};

const getDate = (date: Date) => {
  return new Date(date).toLocaleDateString('nb-NO', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  });
};

const truncateString = (str: string, num: number) => {
  if (str) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  }
  return '';
};

export {
  getLocale,
  getQueryParam,
  getWindowLocation,
  initUserContext,
  getEnvironmentForUrl,
  numberWithSpaces,
  formatDate,
  getReportsDashboardUrl,
  getFrontpageEnvironmentForUrl,
  getDate,
  truncateString,
  dateAndTimeWithoutTimeZone,
};
