import * as React from 'react';

const ApplicationError: React.FC = () => {
  window.addEventListener('DOMContentLoaded', function () {
    //@ts-ignore (serverError is fetched from layout and we check if it is undefined)
    if (typeof serverError !== 'undefined') {
      const mainContent = document.querySelector('[data-id="main-layout"]') as HTMLDivElement;
      //@ts-ignore
      mainContent.innerHTML = serverError;
      document.title = 'Internal Server Error';
    }
  });
  return null;
};

export default ApplicationError;
