import * as React from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { useTranslation } from 'react-i18next';
import { Mybicon } from '@mybring/iconsystem';

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <>
      <a href="#mybring-main" className="skip-link">
        {t('welcome.skip')}
      </a>
      <div className="pagelayout">
        <Header />
        <main id="mybring-main" data-id="main-layout" className="pagelayout__content">
          <section className="banner">
            <div className="banner__wrap">
              <div className="banner__section">
                <a href="/frontpage/index.html" className="login-cta">
                  <svg className="header__login-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M144 112v51.6H48c-26.5 0-48 21.5-48 48v88.6c0 26.5 21.5 48 48 48h96v51.6c0 42.6 51.7 64.2 81.9 33.9l144-143.9c18.7-18.7 18.7-49.1 0-67.9l-144-144C195.8 48 144 69.3 144 112zm192 144L192 400v-99.7H48v-88.6h144V112l144 144zm80 192h-84c-6.6 0-12-5.4-12-12v-24c0-6.6 5.4-12 12-12h84c26.5 0 48-21.5 48-48V160c0-26.5-21.5-48-48-48h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96z" />
                  </svg>
                  <div>
                    <div className="text-title mvxs">{t('welcome.login')}</div>
                    <div>{t('welcome.forexisting')}</div>
                  </div>
                </a>
                <a className="register-cta" href="/signup/customer/company">
                  <svg viewBox="0 0 448 512" width="48" height="48">
                    <path d="M436 480h-20V24a24 24 0 00-24-24H56a24 24 0 00-24 24v456H12a12 12 0 00-12 12v20h448v-20a12 12 0 00-12-12zM128 76a12 12 0 0112-12h40a12 12 0 0112 12v40a12 12 0 01-12 12h-40a12 12 0 01-12-12V76zm0 96a12 12 0 0112-12h40a12 12 0 0112 12v40a12 12 0 01-12 12h-40a12 12 0 01-12-12v-40zm52 148h-40a12 12 0 01-12-12v-40a12 12 0 0112-12h40a12 12 0 0112 12v40a12 12 0 01-12 12zm76 160h-64v-84a12 12 0 0112-12h40a12 12 0 0112 12v84zm64-172a12 12 0 01-12 12h-40a12 12 0 01-12-12v-40a12 12 0 0112-12h40a12 12 0 0112 12v40zm0-96a12 12 0 01-12 12h-40a12 12 0 01-12-12v-40a12 12 0 0112-12h40a12 12 0 0112 12v40zm0-96a12 12 0 01-12 12h-40a12 12 0 01-12-12V76a12 12 0 0112-12h40a12 12 0 0112 12v40z" />
                  </svg>
                  <div>
                    <div className="text-title mvxs">{t('welcome.register1')}</div>
                    <div>{t('welcome.register2')}</div>
                  </div>
                </a>
                <a className="register-cta" href="/signup/user/profile">
                  <svg viewBox="0 0 640 512" width="48" height="48">
                    <path d="M624 208h-64v-64c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v64h-64c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h64v64c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16v-64h64c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm-400 48c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
                  </svg>
                  <div>
                    <div className="text-title mvxs">{t('welcome.register3')}</div>
                    <div>{t('welcome.register4')}</div>
                  </div>
                </a>
              </div>
            </div>
          </section>
          <section className="features">
            <h1 className="features__title">{t('welcome.features.heading')}</h1>
            <div className="features__group">
              <article className="features__item">
                <Mybicon name="mybicon-box" />
                <h2 className="text-heading mbxs">{t('welcome.features.booking')}</h2>
                <p>{t('welcome.features.bookingIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-search" />
                <h2 className="text-heading mbxs">{t('welcome.features.tracking')}</h2>
                <p>{t('welcome.features.trackingIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-chart-bar" />
                <h2 className="text-heading mbxs">{t('welcome.features.reports')}</h2>
                <p>{t('welcome.features.reportsIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-file-invoice" />
                <h2 className="text-heading mbxs">{t('welcome.features.invoices')}</h2>
                <p>{t('welcome.features.invoicesIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-clipboard-list" />
                <h2 className="text-heading mbxs">{t('welcome.features.supportcases')}</h2>
                <p>{t('welcome.features.supportcasesIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-warehouse" />
                <h2 className="text-heading mbxs">{t('welcome.features.warehousing')}</h2>
                <p>{t('welcome.features.warehousingIntro')}</p>
              </article>
              <article className="features__item">
                <Mybicon name="mybicon-users-cog" />
                <h2 className="text-heading mbxs">{t('welcome.features.useradmin')}</h2>
                <p>{t('welcome.features.useradminIntro')}</p>
              </article>
            </div>
            <div className="mtl">
              <a className="text-heading" href="https://www.bring.no/radgivning/mybring">
                {t('welcome.features.link')}
              </a>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Welcome;
