import * as React from 'react';

const NotFound: React.FC = () => {
  window.addEventListener('DOMContentLoaded', function () {
    //@ts-ignore (notFound is fetched from layout and we check if it is undefined)
    if (typeof notFound !== 'undefined') {
      const mainContent = document.querySelector('[data-id="main-layout"]') as HTMLDivElement;
      //@ts-ignore
      mainContent.innerHTML = notFound;
      document.title = '404 Not Found';
    }
  });
  return null;
};

export default NotFound;
