import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import '../common/i18n/i18n';
import App from './components/App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter basename="/">
    <App />
  </BrowserRouter>
);
