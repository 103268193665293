const en = {
  error: {
    unknownError: 'An error occurred. Please try again later.',
  },
  heading: {
    entrances: 'Area entrances',
    tasks: 'Notifications',
    dashboard: 'Dashboard',
    info: 'Information and support',
    serviceChange: 'Service changes'
  },
  resources: {
    booking: 'Booking',
    orderManagement: 'Order Management',
    airAndSea: 'Air and Sea',
    calculator: 'Calculator',
    tracking: 'Tracking',
    reports: 'Reports',
    invoices: 'Invoices',
    docSearch: 'Document search',
    supportCases: 'Customer service',
    useradminExternal: 'Administration',
    customerSearch: 'Customer Search',
    warehousing: 'Warehousing',
    incoming: 'Incoming shipments',
    checkout: 'Checkout',
    marketing: 'Marketing',
    addresses: 'Address files',
    noAccess: 'You do not have access to',
    getAccess: 'You can apply for access and rights via <0>your profile</0>.'
  },
  entrances: {
    noShow: 'No tiles are showing, add one by clicking',
    showTile: 'Show tile',
    move: 'Move',
    left: 'to the left',
    right: 'to the right',
    hide: 'Hide',
    editEntrances: 'Configure tiles',
    closeConfiguration: 'Close configuration',
    configInfo: 'You can change the order by using drag-n-drop or the buttons on each tile.',
    hiddenTilesHeading: 'Hidden tiles',
    hiddenTilesInfo: 'If you choose to hide some tiles, they will be listed here. You can always show them again.',
    disabledAccessHeading: 'Disabled access'
  },
  customerService: {
    label: 'Contact customer service',
    openingHours: 'Monday–Friday, ',
    keypad: 'Mybring: press ',
    trackingKeypad: 'Tracking: press ',
    then: ' then ',
    email: 'Email',
    phone: 'Telephone',
    openCase: 'Create a case in',
    guides: 'Mybring and API resources',
    mybringInfo: 'Get help with Mybring on our',
    mybringInfoLinkText: 'resources pages',
    mybringInfoLink: 'https://www.bring.com/resources/mybring',
    apiInfo: 'Read about our APIs and how to integrate with them in the',
    apiInfoLink: 'Bring API documentation',
    apiUpdatesHeading: 'Subscribe to API updates',
    apiUpdatesText: 'Subscribe to <0>API updates</0> from Bring.',
    apiUpdatesButton: 'Register email',
    feedback: 'Feedback',
    feedbackHead: 'Help us improve Mybring',
    feedbackInfo: 'Do you have input on how we can improve Mybring? Give feedback directly to the development teams.',
    feedbackForm: 'Open form',
  },
  revisedServices: {
    description: 'Some of your customer numbers have services with new names and pricing models.',
    link: 'Name and pricing changes',
  },
  oebsW3: {
    apiLabel: 'Changes to the API',
    webLabel: 'Changes to the web',
    intro: 'From 02 May 2023 we will switch to a new and improved invoicing system for our Home Delivery services in Norway and Denmark as well as regional courier services in Finland. Below is an overview of the changes you will see in various places in Mybring and our APIs.',
    api: {
      title: "Changes in Mybring's APIs due to new invoicing system",
      intro:
        'From 02 May 2023 we will switch to a new and improved invoicing system for our Home Delivery services in Norway and Denmark as well as regional courier services in Finland. Below is an overview of the changes that apply to you as an API customer.',
      customernumbers: ' Your API customer numbers.',
      sg: {
        label: 'Shipping Guide API',
        info1:
          "Requests for the outgoing Home Delivery services in/to Norway and Denmark, Home Delivery return services in/from Norway and Denmark as well as regional courier services in Finland must be done using main customer number. You have to change to your new API main customer number for such requests. Your API main customer number is identical to your company's main customer number. ",
        info2:
          'Your old API customer number will continue to work during a transition period. However, we recommend that you ',
        link: 'change the API customer number being used on requests for mentioned services',
        text: ' as soon as possible to avoid any problems later.',
      },
      booking: {
        label: 'Booking API',
        info1:
          'Booking of Home Delivery services through the Booking API must be done using main customer number. This applies to both outgoing Home Delivery services in/to Norway and Denmark and Home Delivery return services in/from Norway and Denmark. ',
        info2:
          "You must change to your new API main customer number for such bookings. Your API main customer number is identical to your company's main customer number.",
        info3:
          'Your old API customer number will continue to work during a transition period. However, we recommend that you ',
        link: 'change the API customer number being used for bookings of mentioned services',
        text: ' as soon as possible to avoid any problems later.',
      },
      invoices: {
        label: 'Invoice API',
        info1:
          "Invoices must be retrieved by using the main customer number. Your API main customer number is identical to your company's main customer number. ",
        info2:
          'Your old API customer number will continue to work during a transition period. However, we recommend that you ',
        link: 'change the API customer number being used to retrieve invoices',
        text: ' as soon as possible to avoid any problems later.',
      },
      demosg: {
        label: 'Demo Shipping Guide',
        info: 'After switching to the new invoicing system, you will find Home Delivery services under the "Revised services" tab.',
      },
      web: {
        link: "Overview of changes in Mybring's web portal",
      },
    },
    web: {
      title: 'Changes in Mybring due to new invoicing system',
      intro:
        'From 02 May 2023 we will switch to a new and improved invoicing system for our Home Delivery services in Norway and Denmark as well as regional courier services in Finland. Below is an overview of the changes you will see in various places in Mybring.',
      booking: {
        label: 'Booking',
        new: 'New booking',
        info: 'Booking of Home Delivery services in/to Norway and Denmark must be done using main customer number. This applies to both outgoing Home Delivery services in/to Norway and Denmark under "Single outbound” and Home Delivery return services in/from Norway and Denmark under "Single return”. The customer selector will automatically change to show main customer number. For booking of regional courier services in Finland, the customer selector will remain unchanged.',
      },
      pickup: {
        new: 'New pickup',
        info: 'Booking of Home Delivery shipments in Norway must be done using main customer number. The customer selector will automatically change to show main customer number.',
      },
      orderhistory: {
        label: 'Order history',
        info: 'All bookings of Home Delivery services independent of country that are made after switching to the new invoicing system will be shown with main customer number. The same applies to ordered pickups of Home Delivery shipments in Norway. Bookings of regional courier services in Finland will be displayed with the same customer number as before.',
      },
      tracking: {
        label: 'Tracking',
        info: 'There are no changes here. Both old customer number and main customer number are shown as before.',
      },
      invoices: {
        label: 'Invoices',
        info1:
          'Invoices for all bookings for Home Delivery services as well as regional courier services in Finland after switching to the new invoicing system will come under the main customer number.',
        info2:
          'On invoices under main customer number, you will receive more information about your invoice than you receive today, such as:',
        info3: {
          type: 'Invoice type',
          source: 'Invoice source',
          amount: 'Invoice amount',
          currency: 'Currency',
          text1: 'Which original invoice a credit note is linked to',
          text2: 'Whether the invoice has been paid or not',
        },
        info4:
          'You will see some new columns in the column picker for Specified invoice report (i.e the invoice specification) that will have no content. The reason for this extension is that the Specified invoice report becomes a common report for all customers, and some columns are only valid for certain service areas.',
        info5:
          'It is possible to create a subscription for the Specified invoice report. The subscription is created on the main customer number.',
      },
      customerService: {
        label: 'Customer service',
        info: 'There are no changes here. You report cases on main customer number as before.',
      },
      api: {
        label: 'API-changes',
        info: 'You must change to your new API main customer number when using some of the Mybring APIs.',
        link: 'Read more',
      },
    },
  },
  missingPhone: {
    heading: 'Missing phone number',
    text: 'We do not have a phone number registered for your account. This is required to make full use of Mybring services (e.g. receiving SMS messages). To update your phone number please go to ',
    link: ' the update profile page.',
  },
  dashboard: {
    select: 'Select customer number',
    title: 'Planned deliveries',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    moredays: 'See more days',
    error: 'Planned deliveries are not ready yet. Please try again after one hour.',
    loading: 'Loading',
    remaining: 'In transit',
    loaded: 'Loaded on vehicle',
    attempted: 'Attempted delivery',
    pickup: 'At pickup point',
    delivered: 'Delivered',
  },
  overview: {
    titleVol: 'Invoiced volume',
    titleCost: ' and cost',
    loading: 'Loading',
    error: 'There is some problem fetching data. Please try again later.',
    menu: {
      thisyear: 'This year so far',
      thismonth: 'This month so far',
      last12month: 'Last 12 months',
      last24month: 'Last 24 months',
    },
    category: 'Shipments',
    info: 'No data available for this customer',
  },
  welcome: {
    skip: 'Skip to content',
    login: 'Log in',
    forexisting: 'For existing users',
    register1: 'Register new company',
    register2: 'Get a customer number and user for log in',
    register3: 'Create additional users',
    register4: 'When the company already has a customer number',
    features: {
      heading: 'Get an overview of your logistics with Mybring',
      booking: 'Booking',
      bookingIntro: 'Book services included in your agreement. Order shipments to single or multiple recipients.',
      tracking: 'Tracking',
      trackingIntro: 'Provides sender with extended information as well as access to signature and change services.',
      reports: 'Reports',
      reportsIntro: 'Get an overview of status, delivery quality, deviations, costs and climate footprint.',
      invoices: 'Invoices',
      invoicesIntro: 'Browse your invoice archive with an overview of payments and due date statuses',
      supportcases: 'Customer service',
      supportcasesIntro:
        'See all registered cases as well as case log and latest status, and create new cases directly in Mybring.',
      warehousing: 'Warehousing',
      warehousingIntro:
        'Get full control over your inventory. Get an overview of your sales and purchase orders and see the latest status.',
      useradmin: 'Administration',
      useradminIntro: "As an administrator you have full control of the company's customer number and Mybring users.",
      link: 'Read more about Mybring',
    },
    customerservice: {
      label: 'Customer service',
      no: 'Bring Norway',
      se: 'Bring Sweden',
      dk: 'Bring Denmark',
      fi: 'Bring Finland',
    },
    terms: 'Terms and conditions',
    download: 'Download',
    policy: 'Privacy policy',
    cookies: 'Cookies',
  },
  agreementConversion: {
    read: 'Read more.',
    here: 'here',
    notAuthorized:
      'The logged-in user is not authorized to access this page. If you have access to the captioned service, but unable to access this page, please contact customer service.',
    infoPage: {
      header: 'Changes in Mybring related to new service names',
      description: {
        para1:
          'Bring is revising the service portfolio. Some of our services have been given new service names, service codes and pricing models. ',
        para2:
          'From a given date, your company will be converted to services with new names (revised services). When at least one customer number attached to your user has been converted, you will book revised services. This is done by using your company’s main customer number. Below is an overview of the various changes you will see in Mybring.',
        para3: 'To read more about the ',
        link: 'revised services',
        text: ' go directly here.',
      },
      api: {
        header: 'API changes',
        text: 'You use the main customer number for revised services. ',
        introduction: 'Changes in API related to conversion',
        bullets: {
          1: 'To request revised services via our APIs you use your new API customer number, which is identical to your company’s main customer number. To read more about revised services in our APIs, click ',
          2: 'To see your API customer numbers, click ',
          3: 'For a limited period, any bookings of old services made with old API customer numbers will automatically be mapped to the ',
          link: 'corresponding ',
          text: 'revised service. ',
          4: 'If you request old services with your new API-customer number, or revised services with your old API customer number, the request will fail. ',
        },
      },
      booking: {
        header: 'Booking',
        order: 'New Order',
        text: 'You start booking revised services when one customer number attached to your user has been converted. ',
        heading: {
          text1:
            'To check which services you can book with old customer numbers and which services you can book with main customer number, click ',
          text2:
            'For services with new service names the API-request is unchanged, except for the way to request value added services. For old services both old and new way of requesting can be used. However, it is recommended to use the new way for all services as the old way of requesting value added services will be phased out. ',
        },
        table: {
          row: {
            BPAKKE: 'Business Parcel',
            EKSPRESS09: 'Business Parcel Express Overnight',
            CARGO: 'Groupage and Partload*',
            SERVICEPAKKE: 'Climate-neutral Service Parcel',
            PA_DOREN: 'Home Delivery Parcel',
            BPAKKE_RETURSERVICE: 'Business Package Return Service',
            EKSPRESS09_RETURSERVICE: 'Business Parcel Express Overnight Return Service',
            SERVICEPAKKE_RETURSERVICE: 'Service Parcel Return Service',
            5000: 'Business Parcel',
            4850: 'Express next day',
            5100: 'Business groupage',
            5300: 'Business partload',
            5800: 'Pick-up parcel',
            5600: 'Home delivery parcel',
            9000: 'Return business parcel',
            9600: 'Return express',
            9100: 'Return business groupage',
            9300: 'Return from pick-up point',
          },
        },
        text1:
          'After being converted, you will be redirected back to the booking home page if you try to access old services by using bookmarks etc.',
        text2:
          "For revised services, eAdvising is no longer a separate value added service: it's now included in the service and it's base price. Letter notification can be ordered at an additional cost (possible when booking single outbound shipments).",
        flow3: 'Mailbox Packet',
        oldImage: 'Example - Mailbox Packet customer selector before any conversion:',
        newImage:
          'Example - Mailbox Packet customer selector after conversion. Bring Company1 AS and Bring Company3 are converted/main customer numbers. The others have not been converted yet:',
      },
      pickup: {
        header: 'New Pickup',
        text: 'For pickups in Norway, the customer selector will change when you have at least one converted customer number attached to your user. ',
        text1:
          'The customer selector for pickups in Norway will change when you have at least one converted customer number attached to your user. From that point of time, also the customer selector will show at least that one main customer number for invoicing. For parcel pickups in Sweden and Denmark, there will be no changes to the customer selector - i.e. it will continue to show only old customer numbers.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
        oldImage: 'Example – Pickup customer selector before any conversion:',
        newImage:
          'Example – Pickup customer selector after conversion. Bring Company1 AS and Bring Company3 AS are converted/main customer numbers. The others have not been converted yet:',
      },
      orderHistory: {
        header: 'Order history',
        text: 'All bookings are reflected in order history. For old service bookings, you will see the old customer number being displayed. For revised service bookings, you will see the main customer number being displayed.',
      },
      addressbook: {
        header: 'Address book',
        text: 'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. ',
        list: {
          text1:
            'When a customer number attached to your user has been converted, all contacts saved on the old customer number will be migrated to the main customer number. Similarly, all contacts saved on old customer numbers that are not migrated yet will remain on the old customer number. ',
          text2:
            "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
          text3: 'New contacts can be added to both old and main customer numbers.',
        },
        image: {
          oldText: 'Example - Address book customer selector before any conversion: ',
          newText:
            'Example - Address book customer selector after conversion. Bring Company1 AS (1000-12345678) and Bring Company1 AS (00011111111) are now converted and merged under the main customer number Bring Company1 AS (11111111). The others have not been converted yet: ',
        },
      },
      shoppingCart: {
        header: 'Shopping cart',
        text: 'Any items in your shopping cart involving old services will automatically be deleted when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Shopping cart before any conversion (including an old service):',
        newImage: 'Example - Shopping cart after conversion (the old service has been deleted):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you had any items in your shopping cart involving old services, they have been deleted after the conversion. Your shopping cart will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you had any items in your shopping cart involving other services, they still remain in the shopping cart after the conversion.',
      },
      favourites: {
        header: 'Favourites',
        text: 'Any items among your favourites involving old services will automatically be disabled for booking when one of the customer numbers attached to your user has been converted. ',
        oldImage: 'Example - Favourites before any conversion (including an old sevice):',
        newImage: 'Example - Favourites after conversion (the old service has been disabled for booking):',
        text1:
          'When at least one customer number attached to your user has been converted, you can only book revised services. If you have any favourites involving old services, they have been disabled for booking after the conversion. Your favourites page will display a message informing about this.',
        text2:
          'This change only applies to the group of services that are impacted by the conversion. If you have any items among your favourites involving services that are not impacted, they still remain bookable from the favourites page after the conversion.',
        text3:
          'To save the corresponding revised service as a favourite, click "Save as favourite" on the confirmation page next time you book this service.',
      },
      returnAdmin: {
        header: 'Return admin',
        text: 'When a customer number has been converted, any return agreements for old services will automatically be migrated to return agreements for the corresponding revised services. The return agreement for the revised service will be using the main customer number. If you create a new return agreement for a revised service on a converted customer number, you must use the main customer number when searching. For new return agreements on customer numbers that are not converted, the old customer number is used. The same goes for new return agreements for services outside scope of conversion - even though the customer is converted.',
        text1: 'Old return services impacted by the conversion and the new service name/code they are mapped to:',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
          row1: {
            oldService: {
              text: 'Business Package Return Service',
              code: 'BPAKKE_DOR-DOR_RETURSERVICE (1225)',
            },
            newService: {
              text: 'Return business parcel',
              code: '9000',
            },
          },
          row2: {
            oldService: {
              text: 'Business Parcel Express Overnight Return Service',
              code: 'EKSPRESS09_RETURSERVICE (1224)',
            },
            newService: {
              text: 'Return express',
              code: '9600',
            },
          },
          row3: {
            oldService: {
              text: 'Service Parcel Return Service',
              code: 'SERVICEPAKKE_RETURSERVICE (1207)',
            },
            newService: {
              text: 'Return from pick-up point',
              code: '9300',
            },
          },
        },
        text2: '',
      },
      adminBulksplit: {
        header: 'Bulk admin',
        text: 'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements.',
        text1:
          'Bulk agreements only apply to services that are not covered by the conversion, and there are no changes to existing bulk agreements. It’s possible to search for customers in bulk admin by using old customer numbers or main customer numbers, depending on whether the customer is converted yet or not. When searching for main customer numbers, relevant old customer numbers will show, and the user must choose which old customer number to create the bulk agreement on.',
        imageText: 'Example - Search in bulk admin for converted customer: ',
      },
      calculator: {
        header: 'Calculator',
        text: 'You can calculate prices and leadtimes for revised services when one customer number attached to your user has been converted. ',
        text1:
          'The customer selector in calculator will change when you have at least one converted customer number attached to your user. From that point of time, you can use the main customer number to request for prices and leadtimes for the revised services Business groupage and Business partload.',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector. In that case, you can still calculate prices and leadtimes for the old service Groupage and Partload by using one of the old customer numbers in the dropdown.",
        text3:
          'Since the old service Groupage and Partload is split into two revised services, Business groupage and Business partload, you must now select which of the revised services you are requesting for when using the calculator with your main customer number.',
        oldImage: 'Example - Calculator before any conversion (old customer number/old service):',
        newImage: 'Example - Calculator after conversion (main customer number/revised services):',
      },
      tracking: {
        header: 'Tracking',
        text: 'There will be no changes in tracking. We display both the old and the main customer number, as before.',
      },
      reports: {
        header: 'Reports',
        text: 'In the customer selector we hide the old customer numbers after conversion, except from Bring Parcels AB. ',
        subheading: 'Reports & Subscriptions',
        text1:
          'Reports are requested using main customer numbers, and will include all old customer numbers for the selected service type. If you previously had 3 customer numbers for groupage that are all combined within the same main customer number, all of these will be included in the report.',
        text2: 'The columns in the reports are kept, and both old and new services are included in the same report.',
        heading: 'Reports Subscriptions',
        text3:
          'We move subscriptions from old customer numbers to main customer numbers, with the same setup as before. The subscription owner will get an email when that happens. If you used to have subscriptions for multiple customer numbers that are combined within the same main customer number, you might end up with duplicate subscriptions. In that case, you can delete these in Reports-Subscriptions.',
        oldImage: 'Example of old and new customer selector',
        newImage: 'Subscription page where you can edit your subscriptions',
      },
      dashboard: {
        header: 'Dashboard',
        text: 'The old customer numbers will not be available, but the service types will be. If you previously had 3 customer numbers for groupage that now are combined within the same main customer number, these will be combined in the reporting.',
      },
      invoices: {
        header: 'Invoices',
        text: 'When your customers are converted, the old customer numbers are removed from the customer selector. Invoices for old customer numbers are available when selecting main customer number. Invoices will still be available for one year.',
      },
      customerservice: {
        header: 'Customer Service',
        text: 'No changes. You still create cases using main customer numbers, as before.',
      },
      orderManagement: {
        header: 'Order Management',
        text: 'For all APIs, we will use new customer number instead of old customer number. Also, all suppliers will be ported to new customer number. For more details',
        text1:
          "Users having customers for which agreement has been migrated to new customer number, will use new customer number for all API's and UI.",
        heading1: {
          label: 'API Changes',
          subheading1: {
            label: 'IsCustomerValidForOrderManagement',
            text: 'For this api new customer number will be used, because for a customer for which agreement has been migrated to new customer all pickup order will be cretaed on new customer number.',
          },
          subheading2: {
            label: 'ListOrderInformation',
            text: 'As order will be created using new customer number, so to list order info new customer number will be used.',
          },
          subheading3: {
            label: 'ListOrderItems',
            text: 'As order will be created using new customer number, so to list order items new customer number will be used.',
          },
          subheading4: {
            label: 'SenderStatusForOrder',
            text: 'User will have to use new customer number to find sender status.',
          },
          subheading5: {
            label: 'ListPackagingListForOrder',
            text: 'Users will have to use new customer number to get packaging list of order as order for agreement converted customers will be created using new customer number.',
          },
          subheading6: {
            label: 'Report API',
            text: 'For all the order for which booking has been done using new customer number, will have to use new customer number to generate all types of reports.',
          },
        },
        heading2: {
          label: 'Mybring UI Changes',
          text1:
            'Users will have to search purchase order using new customer number for converted customer (dropdown will show new customer number for customer having agreement with new customer number, and for customers with old agreement, old customer number will be displayed.)',
          text2:
            'User will have to create supplier using new customer number for any customer that is new to Purchase order',
          text3:
            'User having suppliers on old customer number will automatically changed to supplier with new customer number',
          text4:
            'All suppliers that has to be used in purchase order by a user, should be added to that user using super user profile.',
        },
      },
      shippingguide: {
        header: 'Shipping guide',
        text1: 'You need to upgrade to ',
        link: 'Shipping Guide 2.0 ',
        text2:
          'to be able to request services with new service names after conversion. Shipping Guide 2.0 supports both old services and services with new names, so it is recommended that you upgrade as quickly as possible to avoid that your integration stops working.',
      },
      newPickup: {
        header: 'New pickup',
        text: 'After your company’s conversion date, you start requesting pickups in Norway with your new API customer number. Your old API customer number will still work for a transition period. However, it is recommended to use your new API customer number as soon as you are converted. For pickups outside Norway there are no changes, and you continue to use your old API customer number.',
      },
      customerServiceMapping: {
        header: 'Mapping between customer number and available services',
        text: 'Which customer numbers attached to your user that can be used to book which services.',
        superuser:
          "You are logged in as a superuser. As a superuser you don't have any customer numbers attached to your user, hence customer/service mapping in not available for you.",
        customer: {
          name: 'Name',
          customerNo: 'Customer Number',
          code: 'Country Code',
          products: 'Products',
        },
      },
      promotions: {
        text1:
          'When you do a coverage search NO -> NO with at least one converted customer number attached to your user, you will see revised services instead of old services. When booking revised services, you will be using your main customer number - i.e the customer selector will show your main customer number(s) for invoicing. The aforementioned changes apply to single outbound/return as well as multiple outbound/return.',
        text2:
          'There are no changes to services that apply to other country combinations, and you will continue using your old customer number for these bookings. To check which services you can book with which customer number, click ',
        text3: 'Old services impacted by the conversion, and which new service name/code they are mapped to: ',
        table: {
          heading1: 'Old service name',
          heading2: 'Old service code',
          heading3: 'New service name',
          heading4: 'New service code',
        },
      },
      cargo:
        '* There is a one-to-one mapping between the old and revised services (and thereby their respective booking flows) - except for the old service Groupage and Partload. This service had one common booking flow, but is split into two revised services/booking flows: Business Groupage and Business Partload. A separate booking flow has also been created for the revised service Return business groupage (New order -> Single return). The old corresponding service was booked via the booking flow for Groupage and Partload by selecting "Return" on the shipping details page. ',
      coverageSearch: {
        oldImage: 'Outgoing coverage search NO -> NO before conversion (old services): ',
        newImage: 'Outgoing coverage search NO -> NO after conversion (revised services): ',
      },
      orderToMailbox: {
        text1:
          'The customer selector for Mailbox Packet will change when you have at least one converted customer number attached to your user. From that point of time, the customer selector will show at least that one main customer number for invoicing. ',
        text2:
          "Not all organizations are converted simultaneously. If you have multiple organizations' customer numbers attached to your user, you might see both old and main customer numbers in the selector.",
      },
    },
  },
  operationalMessages: {
    backtofrontpage: 'Back to the front page',
    select: 'Select country',
    title: 'Operational messages',
    seemore: 'More operational messages',
    loading: 'Loading',
    country: {
      no: 'Norway',
      se: 'Sweden',
      dk: 'Denmark',
    },
    error: 'There is some problem fetching operational messages. Please try again later.',
    none: 'No messages to display.',
  },
  notcollectedatpup: {
    label: 'Not collected at pickup point',
    onetotwodays: '1-2 days left',
    threetofourdays: '3-4 days left',
    error: 'There is some problem fetching data. Please try again later.',
  },
  taskCenter: {
    messages: 'Messages',
    useradmin:'Administration',
    cases: {
      caseNumber: 'Case: ',
      status: {
        NEW: 'New',
        IN_PROGRESS: 'In progress',
        AWAITING_CUSTOMER: 'Awaiting customer',
        CLOSED: 'Closed',
      },
    },
  },
  searchable_select: {
    customerEmptyText: 'No customers matches the given search criteria...',
    customerPlaceholderText: 'Search and select a customer...',
    listboxLabelCustomer: 'List of selectable customers',
  },
  bankInfo: {
    header: 'The Posten Bring Group changes bank',
    p1: 'Posten Bring AS and its subsidiaries have recently changed banks. The new payment information can be found on the invoices. To ensure the payment of the invoices and to avoid delays, the new payment information must be updated in your financial system.',
    p2: 'Read more here: ',
    ctaLabel: 'Change of group bank for Posten Bring AS',
    ctaLink: 'https://www.bring.no/en/customer-service/change-group-bank'
  },
  checkITContactInfo: {
    header: 'Free checkout advice for online stores',
    text: 'Do you want help to optimize your checkout? Our checkout experts are ready to help you! Book a meeting with our advisor here: <0>Schedule meeting</0> or contact us at <1>checkout.implementering@posten.no</1>.',
    emailLink: 'mailto:checkout.implementering@posten.no',
  }
};

export default en;
