import * as React from 'react';

type Props = {
  fallbackUI: React.ReactChild;
  children: React.ReactChild;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) return this.props.fallbackUI;
    return this.props.children;
  }
}

export default ErrorBoundary;
